<template>
  <div class="page-container">
    <nav-bar title="在线报名" :fixed="true" id="navbar"></nav-bar>
    <!-- 报名表单 -->
    <div class="form-area" v-if="!isSignupSuccess && !isSignupConfirm">
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
        请您如实填写，进入教学平台学习需要验证报名信息！
      </van-notice-bar>
      <van-form @submit="onConfirm" ref="formData">
        <van-field
          v-model="username"
          name="username"
          label="真实姓名"
          placeholder="真实姓名"
          :rules="[{ required: true, message: '请填写真实姓名' }]"
        />
        <van-field
          v-model="certNum"
          name="certNum"
          label="身份证号"
          placeholder="身份证号"
          maxlength="18"
          :rules="[{ required: true, message: '请填写身份证号码' }]"
        />
        <van-field name="sex" label="性别">
          <template #input>
            <van-radio-group v-model="sex" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="tel"
          name="tel"
          type="tel"
          label="手机号码"
          placeholder="手机号码"
          maxlength="11"
          @input="onPhoneChange"
          :rules="[{ required: true, message: '请填写报名手机号码' }]"
        />
        <van-field
          v-model="sms"
          name="sms"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
          maxlength="6"
          :rules="[{ required: true, message: '请填写短信验证码' }]"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              @click="sendSmsCode"
              native-type="button"
              :disabled="isSmsSend"
              >{{ sendBtnText }}</van-button
            >
          </template>
        </van-field>
        <van-field
          v-model="corpName"
          name="corpName"
          label="所属企业"
          placeholder="请选择所属企业"
          readonly
          @click="openIndexBar"
          :rules="[{ required: true, message: '请选择所属企业' }]"
        />
        <van-field
          v-model="fieldValue"
          name="fieldValue"
          is-link
          readonly
          label="人员类型"
          placeholder="请选择报名人员类型"
          @click="show = true"
        />
        <van-popup v-model="show" round position="bottom">
          <van-cascader
            v-model="cascaderValue"
            title="请选择报名人员类型"
            :options="options"
            @close="show = false"
            @finish="onFinish"
          />
        </van-popup>
        <van-cell
          title="课程名称"
          :value="planName"
          label="请您仔细确认课程名称无误"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>

    <!-- 报名信息确认 -->
    <div class="confirm-area" v-if="isSignupConfirm">
      <div class="checked-plan">
        <van-cell-group title="请确认您的报名信息" inset>
          <van-cell title="真实姓名" :value="username" />
          <van-cell title="身份证号" :value="certNum" />
          <van-cell title="性别" :value="sex==='1'?'男':'女'" />
          <van-cell title="手机号码" :value="tel" />
          <van-cell title="所属企业" :value="corpName" />
          <van-cell title="人员类型" :value="fieldValue" />
          <van-cell title="报名课程" :value="planName" />
          <div style="margin: 16px; text-align: center">
            <van-button round plain type="info" native-type="button" @click="onModify"> 修改 </van-button>&nbsp;
            <van-button round type="info" native-type="button" @click="onConfirmSubmit"> 确认提交 </van-button>
          </div>
        </van-cell-group>
      </div>
    </div>

    <!-- 报名成功 -->
    <div class="checked-area" v-if="isSignupSuccess">
      <van-icon name="checked" color="#67C23A" size="80" />
      <div><span class="checked-msg">恭喜您，报名成功！</span></div>
      <div class="checked-plan">
        <van-cell-group title="报名信息" inset>
          <van-cell title="真实姓名" :value="username" />
          <van-cell title="身份证号" :value="certNum" />
          <van-cell title="性别" :value="sex==='1'?'男':'女'" />
          <van-cell title="手机号码" :value="tel" />
          <van-cell title="所属企业" :value="corpName" />
          <van-cell title="人员类型" :value="fieldValue" />
          <van-cell title="报名课程" :value="planName" />
          <div style="margin: 16px">
            <van-button
              round
              block
              type="info"
              native-type="button"
              @click="gotoStudy"
              >点击进入在线教育平台，开始学习吧！</van-button
            >
          </div>
        </van-cell-group>
      </div>
    </div>

    <!-- 企业索引页 -->
    <van-popup
      v-model="showIndexBar"
      position="left"
      :style="{ width: '100%', height:'100%' }">
        <van-search
            v-model="searchCorpValue"
            show-action
            placeholder="请输入搜索企业关键词"
            @search="onCorpSearch"
            @cancel="onCorpSearchCancel"
            @clear="onCorpSearchClear"
        />
        <van-index-bar ref="indexBar">
            <template v-for="(item, index) in enterpriseIndexData">
                <van-index-anchor :index="index" :key="index">{{ index }}</van-index-anchor>
                <van-cell
                    :title="item1.corpName"
                    @click="selectCorp(item1.indexChar, item1.corpId, item1.corpName)"
                    v-for="(item1, index1) in item" :key="index1">
                </van-cell>
            </template>
        </van-index-bar>
    </van-popup>

  </div>
</template>

<script>
import { NavBar, Toast } from "vant";
import {
  getEnterpriseIndexList,
  getStudyPlanInfo,
  getPhoneCode,
  checkPhoneCode,
  signup,
} from "../../api/user";
export default {
  name: "signup-shuangpu",
  components: { NavBar },
  data() {
    return {
      enterpriseIndexData: [],
      isSearched: false,
      searchCorpValue: '',
      indexChar: '',
      showIndexBar: false,
      isSignupSuccess: false,
      isSignupConfirm: false,
      // 是否已经发送了验证码
      isSmsSend: false,
      // 文本
      sendBtnText: "发送验证码",
      // 计时器对象
      timer: null,
      // 倒数60秒
      counter: 60,
      tel: "",
      sms: "",
      username: "",
      certNum: "",
      sex: '1',
      show: false,
      fieldValue: "",
      cascaderValue: "",
      categroy: "",
      pCategory: "",
      cityCode: "140900",
      agentId: '12',
      planName: "",
      corpName: "",
      corpId: "",
      // 选项列表，children 代表子选项，支持多级嵌套
      options: [
        {
          text: "驾驶员",
          value: "122",
          children: [
            { text: "货物运输", value: "124" },
          ],
        },
        {
          text: "两类人员",
          value: "126",
          children: [
            { text: "主要负责人", value: "127" },
            { text: "安全管理人员", value: "128" }
          ],
        },
      ],
    };
  },
  mounted() {
      this.searchEnterpriseList(this.cityCode, this.agentId, '');
  },
  methods: {
    getStudyPlanInfo(cityCode, categoryId) {
      getStudyPlanInfo({
        cityCode,
        categoryId,
      })
        .then((res) => {
          this.planName = res.planName;
        })
        .catch(() => {});
    },
    searchEnterpriseList(cityCode, agentId, keyword) {
      getEnterpriseIndexList({
        cityCode: cityCode,
        agent: agentId,
        enterpriseName: keyword
      })
        .then((res) => {
          this.enterpriseIndexData = res;
        })
        .catch(() => {});
    },
    onConfirm() {
		if(this.pCategory == '126') {
			Toast.fail("两类人员培训即将上线，敬请关注");
			return false;
		}
        this.isSignupConfirm = true;
    },
    onModify() {
        this.isSignupConfirm = false;
    },
    onConfirmSubmit() {
        let form = {
            fieldValue: this.fieldValue,
            tel: this.tel,
            sms: this.sms,
            username: this.username,
            certNum: this.certNum,
            sex: this.sex,
            categroy: this.categroy,
        }
        this.onSubmit(form);
    },
    onSubmit(values) {
      //console.log("submit", values);
      if (values.fieldValue == "") {
        Toast.fail("请您选择人员类型");
        return false;
      }
      // 验证码校验
      checkPhoneCode({
        mobile: values.tel,
        code: values.sms,
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            // 报名
            signup({
              studentName: values.username,
              certNum: values.certNum,
              sex: values.sex,
              mobile: values.tel,
              categoryId: this.categroy,
              cityCode: this.cityCode,
              enterpriseId: this.corpId
            })
              .then(() => {
                this.isSignupConfirm = false;
                this.isSignupSuccess = true;
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
    onFinish({ selectedOptions }) {
      this.pCategory = selectedOptions[0].value;
      this.categroy = selectedOptions[selectedOptions.length - 1].value;
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
      //this.categroy = selectedOptions.map((option) => option.value).join("/");
      //console.log(this.fieldValue);
      //console.log(this.categroy);
      this.getStudyPlanInfo(this.cityCode, this.categroy);
    },
    openIndexBar() {
        this.showIndexBar = true;
        if(this.indexChar !== '') {
            this.$nextTick(()=>{
                this.$refs.indexBar.scrollTo(this.indexChar);
            })
        }
    },
    selectCorp(index, corpId, corpName) {
        this.indexChar = index;
        this.corpId = corpId;
        this.corpName = corpName;
        this.showIndexBar = false;
    },
    onCorpSearch(val) {
        this.searchEnterpriseList(this.cityCode, this.agentId, val);
        this.isSearched = true;
    },
    onCorpSearchCancel() {
        if(this.isSearched){
            this.searchEnterpriseList(this.cityCode, this.agentId, '');
            this.isSearched = false;
        }
        this.showIndexBar = false;
    },
    onCorpSearchClear() {
        if(this.isSearched){
            this.searchEnterpriseList(this.cityCode, this.agentId, '');
            this.isSearched = false;
        }
    },
    sendSmsCode() {
      // 判断手机号是否已经输入
      if (!this.tel) {
        Toast.fail("请输入手机号");
        return false;
      }
      // 判断手机号是否符合要求
      if (this.tel.length !== 11) {
        Toast.fail("请输入11位手机号");
        return false;
      }
      // 调用接口，发送短信验证码
      getPhoneCode({ mobile: this.tel, signup: '1' }).then((res) => {
        if (res.data.smsCode) {
          this.sms = res.data.smsCode;
        }
        // 将按钮禁用，防止再次点击
        this.isSmsSend = true;
        // 开始倒计时，60s之后才能再次点击
        this.countDown(); // 这里实现倒计时的功能，文章下面开始介绍
      });
    },
    reset() {
      this.isSmsSend = false;
      this.sendBtnText = "发送验证码";
      if (this.timer) {
        clearInterval(this.timer);
        this.counter = 60;
        this.timer = null;
      }
    },
    countDown() {
      this.timer = setInterval(() => {
        this.sendBtnText = `(${this.counter}秒)后重新发送`;
        this.counter--;
        if (this.counter < 0) {
          this.reset();
        }
      }, 1000);
    },
    onPhoneChange() {
      this.reset();
    },
    gotoStudy() {
      var categoryId = this.pCategory;
      console.log(categoryId);
      window.location.href = "/index.html#/?appNo=APP03";
    },
  },
};
</script>

<style scoped>
#navbar {
  background-color: #409eff;
}
#navbar /deep/ .van-ellipsis {
  color: #fff !important;
}
.form-area {
  margin-top: 46px;
}
.checked-area {
  margin-top: 90px;
  text-align: center;
}
.confirm-area {
  text-align: center;
}
.checked-msg {
  font-size: 14px;
  color: #67c23a;
}
.checked-plan {
  text-align: left;
  margin-top: 40px;
}
</style>
